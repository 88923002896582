<template>
  <div>
    <header>
    <div class="desktop-navigation-wrapper">
      <div class="desktop-navigation-wrapper__logo-wrapper">
        <the-logo></the-logo>
      </div>
      <div>
        <button class="open-menu-btn" @click="showDrawer">
          <i class="material-icons">menu</i>
        </button>
      </div>
    </div>

    <el-drawer
      v-model="drawer"
      title="Избери категория"
      :with-header="false"
      direction="ltr"
      size="320px"
      style="background-color: #f4f2ee; border-right: 1px solid #dfdeda"
      handleClose
      @click="hideDrawer"
    >
      <mobile-navigation></mobile-navigation>
    </el-drawer>
    </header>
  </div>
</template>

<script>
import MobileNavigation from './MobileNavigation.vue';
export default {
  components: {
    MobileNavigation,
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    showDrawer() {
      this.drawer = true;
    },
    hideDrawer() {
      this.drawer = false;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
