<template>
<div>
    <h1>Имоти под наем</h1>
    <div>
        <rent-listing></rent-listing>
    </div>
</div>
</template>

<script>
import RentListing from '../layouts/RentListing.vue' 
export default {
    components: {
        RentListing,
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
h1 {
  margin: 30px 10px;
  color: transparent;
  font-size: 26px;
  font-weight: 400;
  background-image: var(--theme-gradient6);
  background-clip: border-box;
  -webkit-background-clip: text;
  text-transform: uppercase;
}
</style>