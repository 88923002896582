<template>
  <nav class="mobile-navigation">
    <div class="mobile-navigation__logo">
      <the-logo></the-logo>
    </div>
    <ul style="margin-top: 100px;">
      <li style="margin: 20px 0; text-align: left;">
        <router-link to="/" style="color: #666666; text-decoration: none; font-size: 18px;"
          ><el-icon><CaretRight /></el-icon> Начало
        </router-link>
      </li>
      <li style="margin: 20px 0; text-align: left;">
        <router-link to="/for-sale" style="color: #666666; text-decoration: none; font-size: 18px;"
          ><el-icon><CaretRight /></el-icon> Продажба
        </router-link>
      </li>
      <li style="margin: 20px 0; text-align: left;">
        <router-link to="/for-rent" style="color: #666666; text-decoration: none; font-size: 18px;"
          ><el-icon><CaretRight /></el-icon> Наем
        </router-link>
      </li>
      <li style="margin: 20px 0; text-align: left;">
        <router-link to="/about-us" style="color: #666666; text-decoration: none; font-size: 18px;"
          ><el-icon><CaretRight /></el-icon> За нас
        </router-link>
      </li>
    </ul>

    <p style="text-align: left; color: #666666; margin-top: 100px">
      Floory.EU може да бъде Вашето доверено място за намиране на недвижими имоти в България.
    </p>
  </nav>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
