<template>
<div>
    <h1>Имоти за продажба</h1>
    <div>
        <sales-listing></sales-listing>
    </div>
</div>
</template>

<script>
import SalesListing from '../layouts/SalesListing.vue'

export default {
    components: {
        SalesListing,
        
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
h1 {
  margin: 30px 10px;
  color: transparent;
  font-size: 26px;
  font-weight: 400;
  background-image: var(--theme-gradient6);
  background-clip: border-box;
  -webkit-background-clip: text;
  text-transform: uppercase;
}
</style>