<template>
  <header class="mobile-header">
    <div class="menu-wrapper">
      <div class="menu">
        <el-icon :size="40" color="#ff5c41" @click="showDrawer"
          ><Expand
        /></el-icon>
      </div>
      <div>
        <the-logo></the-logo>
      </div>
    </div>

    <el-drawer
      v-model="drawer"
      title="Избери категория"
      :with-header="false"
      direction="ltr"
      size="320px"
      style="background-color: #f4f2ee; border-right: 1px solid #dfdeda"
      handleClose
      @click="hideDrawer"
    >
      <mobile-navigation></mobile-navigation>
    </el-drawer>
  </header>
</template>

<script>
import MobileNavigation from './MobileNavigation.vue';
export default {
  components: {
    MobileNavigation,
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    showDrawer() {
      this.drawer = true;
    },
    hideDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
